<template>
    <div class="w-100 h-100 d-flex flex-column">
        <h4>Prochaine vidéoconférence</h4>
        <div
            v-if="displayVisios"
            class="mt-4 d-flex justify-content-around align-items-center"
        >
            <p class="m-0 font-weight-bold">{{ displayVisios.name }}</p>
            <div class="d-flex align-items-center ml-5">
                <b-icon class="mr-2" icon="calendar-event-fill" style="width: 12px;" variant="primary"></b-icon>
                <p class="m-0">{{ formatDate(displayVisios.start) }}</p>
            </div>
            <p class="m-0 ml-5">Durée : {{ displayVisios.duration }} minutes</p>
            <div v-if="(tuteur || admin) && showButtonDate(displayVisios.start)">
                <button
                    class="btn btn-icon btn-circle btn-light btn-sm mr-3"
                    v-b-tooltip.hover.top="'Modifier'"
                    @click="editVisios(displayVisios)"
                >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <i class="fas fa-pen"></i>
                    </span>
                </button>
                <button
                    class="btn btn-icon btn-circle btn-light btn-sm"
                    v-b-tooltip.hover.top="'Inscrire des stagiaires'"
                    @click="$bvModal.show('modal-inscription-' + displayVisios.id)"
                >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <i class="fas fa-user-plus"></i>
                    </span>
                </button>
                <RegisterIntern :visio="displayVisios"></RegisterIntern>
            </div>
            <div
                v-if="showButtonDate(displayVisios.start)"
                v-b-tooltip.hover.top="
                    !tuteur && !admin && joinStatus(displayVisios)
                        ? 'La vidéoconférence n\'est pas encore accessible, merci d\'actualiser cette page à ' +
                          formatTime(displayVisios.start) +
                          '.'
                        : ''
                "
            >
                <router-link
                    v-if="!tuteur && !admin"
                    :to="
                        !displayVisios.is_active && !tuteur
                            ? { name: 'classroom-visio-waiting', params: { visioId: displayVisios.id } }
                            : { name: 'classroom-signature', params: { visioId: displayVisios.id } }
                    "
                    replace
                >
                    <b-button
                        :disabled="!tuteur && !admin && joinStatus(displayVisios)"
                        class="btn btn-icon btn-circle d-flex justify-content-center align-items-center"
                        pill
                        variant="primary"
                    >
                        <b-icon icon="play-fill" style="width: 70%; height: auto"></b-icon>
                    </b-button>
                </router-link>
                <router-link v-else-if="!admin" :to="{ name: 'classroom-signature', params: { visioId: displayVisios.id } }" replace>
                    <b-button
                        :disabled="joinStatus(displayVisios)"
                        class="btn btn-icon btn-circle d-flex justify-content-center align-items-center"
                        pill
                        variant="primary"
                    >
                        <b-icon icon="play-fill" style="width: 70%; height: auto"></b-icon>
                    </b-button>
                </router-link>
                <!--                <router-link :to="{ name: 'classes-intern.link' }" replace>-->
                <!--                    <button class="btn btn-icon btn-circle btn-light btn-sm">-->
                <!--                        <span class="svg-icon svg-icon-md svg-icon-primary">-->
                <!--                            <inline-svg src="/media/svg/icons/General/Visible.svg"/>-->
                <!--                        </span>-->
                <!--                    </button>-->
                <!--                </router-link>-->
            </div>
        </div>
        <div v-else>
            Aucune vidéoconférence à venir.
        </div>
        <UpdateVisio :selectedVisio="selectedVisio" @reloadVisios="reloadVisios()"></UpdateVisio>
    </div>
</template>
<script>
import moment from 'moment/moment';

export default {
    name: 'Visios',
    props: {
        videoconferences: {
            type: Array,
            required: true
        }
    },
    components: {
        UpdateVisio: () => import('./UpdateVisio.vue'),
        RegisterIntern: () => import('@/view/content/components/RegisterIntern.vue')
    },
    data() {
        return {
            displayVisios: {},
            tuteur: false,
            admin: false,
            selectedVisio: {
                id: null,
                name: null,
                startDate: null,
                startTime: null,
                duration: null,
                training_time: null
            }
        };
    },
    mounted() {
        this.checkTuteur([1]);
        if (this.$store.getters.currentUser.role === 'admin') {
            this.admin = true;
        }
        this.updateDisplayVisios();
    },
    methods: {
        updateDisplayVisios() {
            // Obtient la date actuel
            const now = moment();
            
            // Fonction de recherche de la vidéoconférence en cours
            const findCurrentVideoconference = videoconference => {
                const startMoment = moment(videoconference.start);
                const endMoment = startMoment.clone().add(videoconference.duration, 'minutes');
                // Vérifie si l'instant actuel est entre le début et la fin de la vidéoconférence
                return now.isBetween(startMoment, endMoment, null, '[]');
            };

            // Fonction de recherche de la prochaine vidéoconférence à venir
            const findNextVideoconference = videoconference => moment(videoconference.start).isAfter(now);

            // Recherche de la vidéoconférence en cours
            const currentVideoconference = this.videoconferences.find(findCurrentVideoconference);

            // Si une vidéoconférence en cours est trouvée, on l'affiche
            if (currentVideoconference) {
                this.displayVisios = currentVideoconference;
            } else {
                // Recherche de la prochaine vidéoconférence à venir
                const nextVideoconference = this.videoconferences.find(findNextVideoconference);
                // Si une prochaine vidéoconférence est trouvée, on l'affiche, sinon, met à jour avec null
                this.displayVisios = nextVideoconference || null;
            }
        },
        editVisios(visio) {
            this.selectedVisio.id = visio.id;
            this.selectedVisio.name = visio.name;
            this.selectedVisio.startDate = moment(visio.start).format('YYYY-MM-DD');
            this.selectedVisio.startTime = moment(visio.start).format('HH:mm');
            this.selectedVisio.duration = visio.duration;
            this.selectedVisio.training_time = visio.training_time;
            this.$bvModal.show('edit-visio');
        },
        showButtonRole() {
            //verifier si l'utilisateur a le droit tuteur ou le role admin
            if (this.admin || this.checkTuteur([1])) {
                return true;
            }
        },
        showButtonDate(date) {
            //verifier si la date de la videoconference est à la date du jour
            if (moment(date).isSame(moment().startOf('day'), 'day')) {
                return true;
            }
        },
        joinStatus(visio) {
            //Si visio.start_date n'est pas aujourdhui, return true
            //Si la visio est aujourdhui mais que l'heure de la visio n'est pas encore arrivée, return true
            if (!moment(visio.start).isSame(moment().startOf('day'), 'day') || moment(visio.start).isAfter(moment())) {
                return true;
            }
        },
        checkTuteur(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY' + ' à ' + 'HH:mm');
            }
        },
        formatTime(date) {
            if (date) {
                return moment(date).format('HH:mm');
            }
        },
        reloadVisios() {
            this.$emit('reloadVisios');
        }
    }
};
</script>
